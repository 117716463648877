<template>
  <div class="wrapper">
    <v-menu ref="parentMenu" offset-y top left rounded="lg" transition="slide-y-transition">
      <template #activator="{ on, attrs }">
        <v-btn color="white" class="button--vert primary--text" v-bind="attrs" v-on="on" @click="$emit('updateDevices')">
          <v-icon v-text="'more_vert'" />
        </v-btn>
      </template>
      <v-list class="py-0">
        <v-menu open-on-hover offset-x left rounded="lg">
          <template #activator="{ on, attrs }">
            <v-list-item class="item--primary" v-bind="attrs" v-on="on">
              <v-list-item-avatar>
                <v-icon v-text="'camera'" />
              </v-list-item-avatar>
              <v-list-item-title class="item__text" v-text="$t('streaming.buttons.switchCamera')" />
            </v-list-item>
          </template>
          <v-list class="py-0">
            <v-list-item
              v-for="device in devices.filter(device => device.kind === 'videoinput')"
              :key="device.deviceId"
              class="item--primary"
              @click="$emit('switchDevice', { type: 'video', device }); $refs.parentMenu.isActive = false"
            >
              <v-list-item-avatar class="mr-1">
                <v-icon v-if="device.deviceId === cameraId" color="primary" v-text="'check'" />
              </v-list-item-avatar>
              <v-list-item-title class="item__text" v-text="device.label" />
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu open-on-hover offset-x left rounded="lg">
          <template #activator="{ on, attrs }">
            <v-list-item class="item--primary" v-bind="attrs" v-on="on">
              <v-list-item-avatar>
                <v-icon v-text="'mic'" />
              </v-list-item-avatar>
              <v-list-item-title class="item__text" v-text="$t('streaming.buttons.switchMicrophone')" />
            </v-list-item>
          </template>
          <v-list class="py-0">
            <v-list-item
              v-for="device in devices.filter(device => device.kind === 'audioinput')"
              :key="device.deviceId"
              class="item--primary"
              @click="$emit('switchDevice', { type: 'audio', device }); ; $refs.parentMenu.isActive = false"
            >
              <v-list-item-avatar class="mr-1">
                <v-icon v-if="device.deviceId === microphoneId" color="primary" v-text="'check'" />
              </v-list-item-avatar>
              <v-list-item-title class="item__text" v-text="device.label" />
            </v-list-item>
          </v-list>
        </v-menu>
        <v-list-item class="item--primary" @click="$emit('switchLayout')">
          <v-list-item-avatar>
            <v-icon v-text="'view_quilt'" />
          </v-list-item-avatar>
          <v-list-item-title class="item__text" v-text="$t('streaming.buttons.switchLayout')" />
        </v-list-item>
        <v-list-item class="item--red" @click="$emit('finish')">
          <v-list-item-avatar>
            <v-icon class="error--text" v-text="'highlight_off'" />
          </v-list-item-avatar>
          <v-list-item-title class="item__text error--text" v-text="$t('streaming.finish')" />
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'MoreOptionsButton',
  props: {
    devices: { type: Array, required: true },
    cameraId: { type: String, required: true },
    microphoneId: { type: String, required: true },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
}

.button--vert {
  padding: 0 !important;
  min-width: 0 !important;
  width: 32px;
  height: 52px !important;
  border-radius: 8px;
}

.item__text {
  padding-right: 26px;
}

// Menu colors
.item--red ::v-deep .v-ripple__animation {
    color: #e02020 !important;
}

.item--red:hover::before {
    background-color: #e02020 !important;
    opacity: 0.15;
}

.item--primary ::v-deep .v-ripple__animation {
    color: #2acccc  !important;
}

.item--primary:hover::before {
    background-color: #2acccc  !important;
    opacity: 0.15;
}
</style>
